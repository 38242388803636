import { extractMedia } from 'ricos-content/libs/extract-media';
import { ITEM_TYPES } from '@wix/advanced-seo-utils';
import { MEMBERS_ABOUT } from '@wix/app-definition-ids';
import { SANTA_MEMBERS_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import {
  POST_STATUS,
  generatePostPageSEOTags,
  getAppConfig,
  getCategoriesMap,
  getCustomPostUrl,
  isExperimentEnabled,
  resolveId,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_SEO_FAQ_DATA } from '@wix/communities-blog-experiments';
import { AppData } from '../../../viewer.app';
import {
  completeFetchPost,
  fetchPost,
  preFetchPost,
} from '../../common/actions/fetch-post';
import { incrementPostViewCount } from '../../common/actions/increment-post-view-count';
import { navigateToCustomPostUrl } from '../../common/actions/navigate-within-blog';
import { pageOpened } from '../../common/actions/page-opened';
import { getQueryLocale } from '../../common/selectors/locale-selectors';
import { POST_PAGE } from '../../common/services/detect-route';
import { resolvePostSlug } from '../../common/services/slug';
import {
  getIsMobile,
  isEditor,
  isSSR,
  isSite,
} from '../../common/store/basic-params/basic-params-selectors';
import { getIsDemoMode } from '../../common/store/instance-values/instance-values-selectors';
import { AppStore, RouteResolverFn, WixCodeApi } from '../../common/types';
import { setReadingSessionId } from '../actions/reading-session-id-actions';
import { ROUTE_404 } from '../constants/routes';
import { getReadingSessionId } from '../selectors/reading-session-id-selector';
import { fetchRecentPostsAndComments } from './fetch-data-for-post-widgets';
import { getSEOFAQData } from './get-seo-faq-data';

export const createPostPageRouter =
  (
    store: AppStore,
    wixCodeApi: WixCodeApi,
    connections: any,
    appData: AppData,
  ): RouteResolverFn =>
  async ({ params }, redirect, { preFetch, preFetchResult }) => {
    const postSlug = resolvePostSlug(params);

    if (preFetch) {
      return store.dispatch(preFetchPost(postSlug));
    }

    const readingSessionId = `${parseInt(
      `${Math.random() * 10000000}`,
      10,
    )}-${Date.now()}`;
    store.dispatch(setReadingSessionId(readingSessionId));

    return (
      preFetchResult
        ? store.dispatch(completeFetchPost(postSlug, preFetchResult))
        : store.dispatch(fetchPost(postSlug))
    )
      .then(async (post) => {
        // HACK: multilingual language change in post page redirect to translation
        const lang = getQueryLocale(store.getState());

        if (lang && lang !== post.language) {
          // @ts-expect-error
          const translation = post.translations.find(
            (trans: any) =>
              trans.language === lang && trans.status === POST_STATUS.published,
          );
          if (translation) {
            store.dispatch(
              navigateToCustomPostUrl(
                getCustomPostUrl(store.getState(), translation.slug),
                `/${translation.slug}`,
              ),
            );
            return;
          } else {
            const error = new Error('Post language mismatch');
            // @ts-expect-error
            error.status = 404;
            throw error;
          }
        }

        if (post.status !== POST_STATUS.published) {
          redirect(`/${resolveId(post)}/edit`);
        }

        const state = store.getState();

        const isMobile = getIsMobile(state);
        // @ts-expect-error
        const referrer = wixCodeApi.window.referrer;
        !isSSR(state) &&
          store.dispatch(
            pageOpened({
              page: POST_PAGE,
              post,
              isMobile,
              isEditor: isEditor(state),
              readingSessionId: getReadingSessionId(state),
              referrer,
            }),
          );

        if (!getIsDemoMode(state) && !isSSR(state)) {
          store.dispatch(incrementPostViewCount(resolveId(post)));
        }

        if (isSite(state)) {
          let mediaUrls;
          if (post.content) {
            try {
              mediaUrls = extractMedia(post.content).map(
                (media) => media.imageUrl || media.videoThumbnailUrl,
              );
            } catch (e) {}
          }

          let ownerUrl;
          try {
            const api = await wixCodeApi.site.getPublicAPI(
              SANTA_MEMBERS_APP_ID,
            );
            if (api) {
              ownerUrl = await api.getSectionUrl({
                appDefinitionId: MEMBERS_ABOUT,
                sectionId: 'about',
                widgetId: '14dbefb9-3b7b-c4e9-53e8-766defd30587',
                memberId: post.owner?.slug,
              });
            }
          } catch (e) {}

          const isSEOFAQDataEnabled = isExperimentEnabled(
            state,
            EXPERIMENT_SEO_FAQ_DATA,
          );

          const faq = isSEOFAQDataEnabled
            ? await getSEOFAQData({ state, wixCodeApi, post })
            : [];

          const itemData = generatePostPageSEOTags({
            appConfig: getAppConfig(state),
            post,
            state,
            categoriesMap: getCategoriesMap(state),
            mediaUrls,
            customPostUrl: getCustomPostUrl(state, post.slug),
            multilingual: wixCodeApi.window.multilingual,
            ownerUrl,
          });

          const postPageSEOTags = {
            itemType: ITEM_TYPES.BLOG_POST,
            itemData: {
              ...itemData,
              ...(isSEOFAQDataEnabled ? { faq } : {}),
            },
            // @ts-expect-error
            seoData: post.seoData,
          };

          wixCodeApi.seo.renderSEOTags(postPageSEOTags);
        }

        await fetchRecentPostsAndComments({
          store,
          post,
          connections,
          appData,
        });
      })
      .catch((error) => {
        if (error.status === 401) {
          return redirect(`/login?redirect=/${postSlug}`);
        }
        if (error.status === 404) {
          return redirect(ROUTE_404);
        }
        throw error;
      });
  };
