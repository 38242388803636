import { fetchTPASettings } from '@wix/communities-blog-client-common';
import { getInstance } from '../../common/controller/helpers';
import { setAppConfig } from '../../common/store/app-config/app-config-actions';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import { setBasicParams } from '../../common/store/basic-params/basic-params-actions';
import { setCommunitiesContext } from '../../common/store/communities-context/communities-context-actions';
import { setCustomRoutes } from '../../common/store/custom-routes/custom-routes-actions';
import { initializeInstanceValues } from '../../common/store/instance-values/instance-values-actions';
import {
  setLocale,
  setQueryLocaleAction,
} from '../../common/store/locale/locale-actions';
import { waitForAction } from '../../common/store/wait-for-action';
import { AppStore, ControllerConfig } from '../../common/types';
import { SET_MAIN_POST } from '../store/main-post';
import { fetchRelatedPostsRenderModel } from './widget-data';

export {
  initializeActions,
  initializePromisifiedActions,
  refreshDataOnLogin,
} from '../../common/controller/init-actions';

type InitializeStoreBaseDataParams = {
  store: AppStore;
  language: string;
  bundleName: string;
  controllerConfig: ControllerConfig;
};

export async function initializeStoreBaseData({
  store,
  language,
  bundleName,
  controllerConfig,
}: InitializeStoreBaseDataParams) {
  const { wixCodeApi, appParams, config, platformAPIs } = controllerConfig;
  const viewMode = wixCodeApi.window.viewMode.toLowerCase();

  store.dispatch(initializeInstanceValues(getInstance(wixCodeApi)()));

  store.dispatch(setLocale(language));
  store.dispatch(setQueryLocaleAction(wixCodeApi, appParams));

  await Promise.all([
    store.dispatch(fetchTPASettings({ language })),
    store.dispatch(setCommunitiesContext()),
    store.dispatch(setAppSettings({ style: config.style.styleParams })),
    store.dispatch(
      setBasicParams({
        viewMode,
        language,
        biPageNumber: platformAPIs.bi?.pageNumber,
        isRendered: true,
      }),
    ),
    store.dispatch(setAppConfig({ bundleName })),
    store.dispatch(setCustomRoutes()),
  ]);

  await store.dispatch(
    waitForAction([SET_MAIN_POST], fetchRelatedPostsRenderModel, true),
  );
}
