import { IPlatformAPI, InitAppForPageFn } from '@wix/yoshi-flow-editor';
import { EXPERIMENTS } from '@wix/communities-blog-client-common';
import { PostPageRenderModel } from './external/blocks-widget/blocks-widget.type';
import { fetchInitialData } from './viewer/init';

export const initAppForPage: InitAppForPageFn = async (
  initParams,
  platformApis,
  wixCodeApi,
  platformServicesApis,
  flowAPI,
) => {
  if (!flowAPI.experiments.enabled(EXPERIMENTS.SPLIT_POST_PAGE)) {
    return { subjects: {}, fetchPostPageRenderModel: () => ({}) };
  }

  let data: PostPageRenderModel;

  return {
    subjects: makeSubjectsForAppData(platformApis),
    fetchPostPageRenderModel: async (): Promise<PostPageRenderModel> => {
      data ??= await fetchInitialData({ wixCodeApi, initParams, flowAPI });
      return data;
    },
  };
};

export type LikeStatusChangeEvent = {
  isLiked: boolean;
  likeCount: number;
};

type SubjectMap = {
  likeStatusChanged: LikeStatusChangeEvent;
  postChange: PostPageRenderModel;
};

function makeSubjectsForAppData(platformApis: IPlatformAPI) {
  function makeSubject<S extends keyof SubjectMap>(subject: S) {
    return {
      next: (payload: SubjectMap[S]) =>
        platformApis.pubSub.publish(subject, payload, true),
      subscribe: (
        callback: (next: {
          data: SubjectMap[S];
          name: typeof subject;
          origin: 'worker';
        }) => void,
      ) => {
        const id = platformApis.pubSub.subscribe(subject, callback, true);

        return {
          unsubscribe: () => platformApis.pubSub.unsubscribe(subject, id),
        };
      },
    };
  }

  return {
    likeStatusChanged: makeSubject('likeStatusChanged'),
  };
}

export type AppData = {
  subjects: ReturnType<typeof makeSubjectsForAppData>;
  fetchPostPageRenderModel: () => Promise<PostPageRenderModel>;
};
