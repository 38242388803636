import { get } from 'lodash';
import { UrlMappingsKeys } from '@wix/url-mapper-utils';
import { IControllerConfig } from '@wix/yoshi-flow-editor';
import { getCurrentUser, isBlocked } from '@wix/communities-blog-client-common';
import { AppData } from '../../../viewer.app';
import { fetchPost } from '../../common/actions/fetch-post';
import { pageOpened } from '../../common/actions/page-opened';
import { createNotFoundPageRouter } from '../../common/controller/create-router';
import { Router } from '../../common/router';
import createPermissionChecker from '../../common/services/create-permission-checker';
import { createPermissionsChecker } from '../../common/services/create-permission-helpers';
import {
  POST_EDIT_PAGE,
  POST_PAGE_NOT_FOUND,
} from '../../common/services/detect-route';
import { resolvePostSlug } from '../../common/services/slug';
import { encodeURIComponentIfNeeded } from '../../common/services/uri';
import {
  isEditor,
  isPreview,
  isSSR,
} from '../../common/store/basic-params/basic-params-selectors';
import { setIsPostInPreview } from '../../common/store/is-post-in-preview/set-is-post-in-preview-action';
import {
  AppState,
  AppStore,
  NormalizedPost,
  RedirectFn,
  RouteResolverFn,
  WixCodeApi,
} from '../../common/types';
import { fetchLastPost } from '../actions/fetch-last-post';
import { biOpenPostPageInEditor } from '../actions/open-post-page-editor';
import {
  ROUTE_404,
  ROUTE_ACCOUNT_SUSPENDED,
  ROUTE_CREATE_POST,
  ROUTE_EDIT_COMMENT,
  ROUTE_EDIT_COMMENT_ADVANCED_SLUG,
  ROUTE_EDIT_POST,
  ROUTE_LAST_POST,
  ROUTE_LOGIN,
  ROUTE_POST,
  ROUTE_POST_ADVANCED_SLUG,
  ROUTE_PREVIEW_POST,
} from '../constants/routes';
import { fetchInitialPostEditorData } from '../services/post-editor';
import { createPostPageRouter } from './create-post-page-router';
import { fetchRecentPostsAndComments } from './fetch-data-for-post-widgets';

const assertUserLoggedInAndNotBlocked = (
  state: AppState,
  redirect: RedirectFn,
) => {
  const currentUser = getCurrentUser(state);
  if (currentUser) {
    if (isBlocked(state)) {
      return {
        isUserValid: false,
        redirectState: redirect(ROUTE_ACCOUNT_SUSPENDED),
      };
    }
  } else {
    return { isUserValid: false, redirectState: redirect(ROUTE_LOGIN) };
  }
  return { isUserValid: true };
};

const createPostPreviewPageRouter =
  (store: AppStore): RouteResolverFn =>
  async ({ params }, redirect) => {
    if (!get(store.getState(), 'auth.isAuthenticated')) {
      return redirect(ROUTE_404);
    }

    const canPreviewPost = (state: AppState, post: NormalizedPost) => {
      const user = getCurrentUser(state);
      const perm = createPermissionChecker(state, user);
      const can = createPermissionsChecker(perm);
      return can('preview', 'post', post);
    };

    const postSlug = resolvePostSlug(params);

    return store
      .dispatch(
        fetchPost(postSlug, { includeDraft: true, instance: params.instance }),
      )
      .then((post) =>
        canPreviewPost(store.getState(), post)
          ? store.dispatch(setIsPostInPreview(true))
          : redirect(ROUTE_404),
      )
      .catch((error) => {
        if (error.status === 401) {
          return redirect(`/login?redirect=/${postSlug}`);
        }
        if (error.status === 404) {
          return redirect(ROUTE_404);
        }
        throw error;
      });
  };

const createPostEditPageRouter =
  (store: AppStore): RouteResolverFn =>
  async ({ params }, redirect) => {
    const state = store.getState();

    const { isUserValid, redirectState } = assertUserLoggedInAndNotBlocked(
      state,
      redirect,
    );
    if (!isUserValid) {
      return redirectState;
    }

    const postSlug = resolvePostSlug(params);
    return fetchInitialPostEditorData(state, store.dispatch, postSlug).then(
      () =>
        !isSSR(state) && store.dispatch(pageOpened({ page: POST_EDIT_PAGE })),
    );
  };

const createPostCreatePageRouter =
  (store: AppStore): RouteResolverFn =>
  async (_route, redirect) => {
    const state = store.getState();

    const { isUserValid, redirectState } = assertUserLoggedInAndNotBlocked(
      state,
      redirect,
    );
    if (!isUserValid) {
      return redirectState;
    }

    const can = createPermissionsChecker(
      createPermissionChecker(state, getCurrentUser(state)),
    );
    if (!can('create', 'post')) {
      return redirect(ROUTE_404);
    }

    return fetchInitialPostEditorData(state, store.dispatch).then(
      () =>
        !isSSR(state) && store.dispatch(pageOpened({ page: POST_EDIT_PAGE })),
    );
  };

const createLastPostPageRouter =
  (store: AppStore, connections: any, appData: AppData): RouteResolverFn =>
  async (_route, redirect) => {
    const state = store.getState();
    if (!(isEditor(state) || isPreview(state))) {
      return redirect(ROUTE_404);
    }

    if (!isSSR(state)) {
      store.dispatch(biOpenPostPageInEditor());
    }

    const lastPost = await store.dispatch(fetchLastPost());

    if (lastPost) {
      await fetchRecentPostsAndComments({
        store,
        post: lastPost,
        connections,
        appData,
      });
    }
  };

const customRouteHandler = async (wixCodeApi: WixCodeApi) => {
  const path = `/${wixCodeApi.location.path
    .map(encodeURIComponentIfNeeded)
    .join('/')}`;
  const { key, segments } =
    (await wixCodeApi?.site?.getCustomizedUrlSegments?.(path)) || {};

  if (!key) {
    return null;
  }

  const slug = encodeURIComponentIfNeeded(segments?.slug);
  const instance = encodeURIComponentIfNeeded(segments?.instance);

  return (
    {
      [UrlMappingsKeys.BLOG_POST]: `/${slug}`,
      [UrlMappingsKeys.BLOG_POST_EDIT]: `/${slug}/edit`,
      [UrlMappingsKeys.BLOG_POST_PREVIEW]: `/${slug}/preview/${instance}`,
    }[key] || null
  );
};

export const createRouter = (
  store: AppStore,
  _config: IControllerConfig,
  wixCodeApi: WixCodeApi,
  isCustomUrlEnabled: boolean,
  connections: any,
  appData: AppData,
) => {
  const router = new Router();
  const basicParams = { viewMode: wixCodeApi.window.viewMode.toLowerCase() };

  if (isEditor({ basicParams }) || isPreview({ basicParams })) {
    router.add(
      ROUTE_LAST_POST,
      createLastPostPageRouter(store, connections, appData),
    );
  }

  router.add(
    ROUTE_404,
    createNotFoundPageRouter(store, wixCodeApi, POST_PAGE_NOT_FOUND, ROUTE_404),
  );
  router.add(ROUTE_LOGIN);
  router.add(ROUTE_CREATE_POST, createPostCreatePageRouter(store));
  router.add(ROUTE_EDIT_POST, createPostEditPageRouter(store));
  router.add(ROUTE_PREVIEW_POST, createPostPreviewPageRouter(store));
  router.add(ROUTE_EDIT_COMMENT);
  router.add(
    ROUTE_POST,
    createPostPageRouter(store, wixCodeApi, connections, appData),
    {
      preFetch: true,
    },
  );
  router.add(
    ROUTE_POST_ADVANCED_SLUG,
    createPostPageRouter(store, wixCodeApi, connections, appData),
    { preFetch: true },
  );
  router.add(ROUTE_EDIT_COMMENT_ADVANCED_SLUG);
  isCustomUrlEnabled &&
    router.addCustomRouteHandler(() => customRouteHandler(wixCodeApi));
  router.fallback(ROUTE_404);
  return router;
};
