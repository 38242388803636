import { I$W, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { BlocksWidgetFacade } from '../../external/blocks-widget/blocks-widget.facade';
import { PostPageRenderModel } from '../../external/blocks-widget/blocks-widget.type';
import { AppData } from '../../viewer.app';
import { PostCategoriesApi } from '../Post Categories/viewer.controller';
import { PostCountersApi } from '../Post Counters/viewer.controller';
import { PostMetadataApi } from '../Post Metadata/viewer.controller';
import { PostTagsApi } from '../Post Tags/viewer.controller';
import blocksModel from './model';

export default blocksModel.createController((params) => {
  const { $w, flowAPI } = params;
  const appData = params.appData as AppData;

  return {
    pageReady: async () => {
      const data = await appData?.fetchPostPageRenderModel();
      setPostPageFooter($w, flowAPI, data);
    },
    exports: blocksModel.getExports(),
  };
});

function setPostPageFooter(
  $w: I$W<Record<string, any>>,
  flowAPI: PlatformControllerFlowAPI,
  model: PostPageRenderModel,
) {
  const widgetData = PostPageRenderModel.toWidgetData(model);
  const blogFacade = new BlocksWidgetFacade(flowAPI);

  const ui = {
    categories: $w('#categories1') as PostCategoriesApi,
    tags: $w('#tags1') as PostTagsApi,
    metadata: $w('#metadata1') as PostMetadataApi,
    counters: $w('#postCountersNew1') as PostCountersApi,
  };

  ui.categories.setCategories(widgetData.categories);
  ui.categories.onCategoryClick((slug) =>
    blogFacade.navigateToCategoryFeed(slug),
  );
  ui.tags.setTags(widgetData.tags);
  ui.tags.onTagClick((slug: string) => blogFacade.navigateToTagFeed(slug));
  ui.metadata.initialize({
    writer: widgetData.writer,
    firstPublishedDate: widgetData.firstPublishedDate,
    timeToRead: widgetData.timeToRead,
    lastPublishedDate: widgetData.lastPublishedDate,
  });
  ui.counters.initialize({
    id: widgetData.id,
    likeCount: widgetData.likeCount,
    viewCount: widgetData.viewCount,
    commentsCount: widgetData.commentCount,
    isLiked: widgetData.isLiked,
  });
}
